import { AwardsApi, AwardSubmissionInfo, Picture, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useState } from "react"
import { useQuery } from 'react-query'
import { Queries } from "../../Constants";
import { ErrorComponent } from "../core/ErrorComponent";
import { LoadingComponent } from "../core/LoadingComponent";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { Link } from "react-router-dom";
import moment from "moment";

function AwardWinnersPanel(props: AwardWinnersPanelProps) {

    const { getApiConfiguration, currentSession } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration())
    const [winners, setWinners] = useState<AwardSubmissionInfo[]>()

    const getWinners = async () => {

        if (!props.awardCategoryID)
            return

        var winners = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
            winningSubmissionsOnly: true,
            selectedAwardCategories: [props.awardCategoryID],
            page: 1,
            pageSize: 4,
            columns: {
                caseStudy: {
                    includeImages: true,
                    includeAdvertiser: true,
                    includeCountries: true
                }
            }
        })

        if (winners.results && winners.results?.length > 0) {
            setWinners(props.skipFirst ? winners.results.slice(1) : winners.results)
        }
        else{
            setWinners(undefined)
        }
    }
    const { status: winnersStatus } = useQuery(Queries.AwardWinners + props.awardCategoryID, getWinners, { refetchOnWindowFocus: false });

    return (
        <>
            {winnersStatus === "error" && <ErrorComponent></ErrorComponent>}
            {winnersStatus === "loading" && <LoadingComponent></LoadingComponent>}
            {winnersStatus === "success" && winners && winners.length > 0 && (
                <>
                    <section className="colour-primary morecasestudies">
                        <div className="container bodycopy clearfix">
                            <h2>
                                Previous Winners
                            </h2>
                            <div className="casestudieswrap clearfix">
                                {winners?.length > 0 ? "" : ""}
                                <div className="slider left">
                                    <div className="slide box relative">
                                        <div className="pill-container" data-position="floating">
                                            <span className="pill pill-dark">{winners[0].awardName} Winner</span>
                                        </div>
                                        <div className="imgwrap absolute">
                                            <Picture url={CaseStudyHelpers.getPrimaryImageUrl(winners[0].caseStudy)} className="absolute" alt="#" height={500} />
                                        </div>
                                        <div className="author colour-primary alpha absolute">
                                            <div className="clearfix">
                                                <div className="left">
                                                    <div className="imageblock">
                                                        <div className="views relative">
                                                            <div>
                                                                <strong>{winners[0].caseStudy?.viewCount}</strong>views
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="headings left">
                                                        <h4>{winners[0].caseStudy?.title}</h4>
                                                    </div>
                                                </div>
                                                <div className="left authorinfo">
                                                    <ul>
                                                        <li><strong>Advertiser: </strong> <span>{winners[0].caseStudy?.tags?.advertiser?.value}</span> </li>
                                                        <li><strong>Implementation Date: </strong><span>{moment(winners[0].caseStudy?.fields?.implementationDate).format("yyyy")}</span></li>
                                                        <li>
                                                            <strong>
                                                                Country:
                                                            </strong>
                                                            {winners[0].caseStudy?.countries?.map(c => c.name).join(",")}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={`/casestudies/` + winners[0].caseStudy?.id} title={winners[0].caseStudy?.title}>
                                            {winners[0].caseStudy?.title}
                                        </Link>
                                    </div>
                                </div>
                                {winners.slice(1).map(entry => {
                                    return (
                                        <div className="left" key={entry.id}>
                                            <div className="box small relative">
                                                <div className="pill-container" data-position="floating">
                                                    <span className="pill pill-dark">{entry.awardName} Winner</span>
                                                </div>
                                                <div className="imgwrap absolute">
                                                    <img src={CaseStudyHelpers.getPrimaryImageUrl(entry.caseStudy)} className="absolute" alt="#" />
                                                </div>
                                                <div className="author colour-primary alpha absolute">
                                                    <h4 className="title">{entry.caseStudy?.title}</h4>
                                                    <a href="#" title="...">
                                                        {entry.caseStudy?.title}
                                                    </a>
                                                </div>
                                                <Link to={`/casestudies/` + entry.caseStudy?.id} title={entry.caseStudy?.title}>
                                                    {entry.caseStudy?.title}
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </section>
                </>)}
        </>
    )
}


interface AwardWinnersPanelProps {
    awardCategoryID?: number,
    skipFirst: boolean
}


export { AwardWinnersPanel }