import { AwardsApi, AwardSubmissionInfo, Picture, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useState } from "react"
import { useQuery } from 'react-query'
import { Queries } from "../../Constants";
import { ErrorComponent } from "../core/ErrorComponent";
import { LoadingComponent } from "../core/LoadingComponent";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { Link } from "react-router-dom";

function AwardEntriesPanel(props: AwardEntriesPanelProps) {

    const { getApiConfiguration, currentSession } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration())
    const [latestEntries, setLatestEntries] = useState<AwardSubmissionInfo[]>()

    const getLatestEntries = async () => {

        if (!props.awardCategoryID)
            return

        var entries = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
            recentSubmissionsOnly: true,
            selectedAwardCategories: [props.awardCategoryID],
            page: 1,
            pageSize: 6,
            columns: {
                caseStudy: {
                    includeImages: true,
                    includeBrand: true,
                    includeCountries: true
                }
            }
        })

        if (entries.results && entries.results?.length > 0)
            setLatestEntries(entries.results)
        else
            setLatestEntries(undefined)
    }

    const { status: latestEntriesStatus } = useQuery(Queries.AwardEntries + props.awardCategoryID, getLatestEntries, { refetchOnWindowFocus: false });

    return (
        <>
            {latestEntriesStatus === "error" && <ErrorComponent></ErrorComponent>}
            {latestEntriesStatus === "loading" && <LoadingComponent></LoadingComponent>}
            {latestEntriesStatus === "success" && latestEntries && (
                <>
                    <section className="morecasestudies">
                        <div className="clearfix">
                            <div className="justified">
                                <hr />
                                <h2>Latest Entries</h2>
                            </div>
                        </div>
                        <div className="casestudieswrap cotm-page clearfix">
                            {latestEntries?.map(entry => {
                                return (
                                    entry.caseStudy ?
                                        <div key={entry.caseStudy.id} className="left">
                                            <div className="box small relative">
                                                <div className="imgwrap absolute">
                                                    <Picture url={CaseStudyHelpers.getPrimaryImageUrl(entry.caseStudy)} className="absolute" alt="#" height={400} />
                                                </div>
                                                <div className="author colour-primary alpha absolute">
                                                    <h4 className="title">{entry.caseStudy?.title}</h4>
                                                </div>
                                                <Link to={`/casestudies/` + entry.caseStudy?.id} title={entry.caseStudy.title}>
                                                    {entry.caseStudy?.title}
                                                </Link>
                                            </div>
                                            <br />
                                            <ul className="authorinfo">
                                                <li><strong>COUNTRY: </strong> {entry.caseStudy.countries?.map(c => c.name).join(",")}</li>
                                                <li><strong>BRAND: </strong>{entry.caseStudy.tags?.brand?.value}</li>
                                            </ul>
                                        </div> : ""
                                )
                            })}

                        </div>
                    </section>
                </>
            )}
        </>
    )
}


interface AwardEntriesPanelProps {
    awardCategoryID?: number,
}


export { AwardEntriesPanel }