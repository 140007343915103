import { Link, useNavigate, useParams } from "react-router-dom";
import { AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import '../../assets/css/slick.css'
import '../../assets/css/slick-theme.css'
import { Queries, Urls } from "../../Constants";
import { useQuery } from "react-query";
import { DataHelpers } from "../../helpers/dataHelpers";
import { Helmet } from "react-helmet";
import { AwardWinnerHeroPanel } from "../../modules/awards/AwardWinnerHeroPanel";
import { AwardEntriesPanel } from "../../modules/awards/AwardEntriesPanel";
import { AwardWinnersPanel } from "../../modules/awards/AwardWinnersPanel";

export function Award() {

    var navigate = useNavigate();
    var params = useParams();
    var shortName = params.shortName ?? "";
    const { getApiConfiguration } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration())

    const fetchAward = async () => {
        var award = await awardsApi.apiAwardsAwardCategoriesNameShortNameGet(shortName);

        if (!award)
            navigate(Urls.NotFound)

        return award
    }

    var { data: awardCategory } = useQuery(Queries.AwardCats + shortName, fetchAward, {refetchOnMount: false, refetchOnWindowFocus: false})

    return (
        <div>
            <Helmet>
                <title>{awardCategory?.name}</title>
            </Helmet>
            <AwardWinnerHeroPanel awardCategoryID={awardCategory?.id} fallbackTitle={awardCategory?.name ?? ""}></AwardWinnerHeroPanel>
            <div className="container bodycopy">
                {awardCategory && (
                    <>
                        <article className="">
                            <div className="justified">
                                <p dangerouslySetInnerHTML={{ __html: DataHelpers.convertToHtml(awardCategory?.description) }}></p>
                                <Link className="button" to={Urls.CaseStudiesCreate}>Create Case Study</Link>
                            </div>
                        </article>
                    </>
                )}
               <AwardEntriesPanel awardCategoryID={awardCategory?.id}></AwardEntriesPanel>
            </div>
            <AwardWinnersPanel skipFirst={true} awardCategoryID={awardCategory?.id}></AwardWinnersPanel>
        </div>
    )
}
