import { AwardsApi, AwardSubmissionInfo, ImageHelpers, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useState } from "react"
import { useQuery } from 'react-query'
import { Queries } from "../../Constants";
import trophy from '../../assets/images/trophy.png'
import { ErrorComponent } from "../core/ErrorComponent";
import { LoadingComponent } from "../core/LoadingComponent";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { Link } from "react-router-dom";

function AwardWinnerHeroPanel(props: AwardWinnerHeroPanelProps) {

    const { getApiConfiguration, currentSession } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration())
    const [winner, setWinner] = useState<AwardSubmissionInfo>()

    const getWinner = async () => {

        if (!props.awardCategoryID)
            return

        var winners = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
            winningSubmissionsOnly: true,
            selectedAwardCategories: [props.awardCategoryID],
            page: 1,
            pageSize: 4,
            columns: {
                caseStudy: {
                    includeImages: true,
                    includeAdvertiser: true,
                    includeCountries: true
                }
            }
        })

        if (winners.results && winners.results?.length > 0) {
            setWinner(winners.results[0])
        }
        else {
            setWinner(undefined)
        }
    }
    const { status: winnersStatus } = useQuery(Queries.AwardHeroWinners + props.awardCategoryID, getWinner, { refetchOnWindowFocus: false });

    return (
        <>
            {winnersStatus === "error" && <ErrorComponent></ErrorComponent>}
            {(winnersStatus === "loading" || !props.awardCategoryID) && <LoadingComponent loadingAnimationDelay={0} height="1000px"></LoadingComponent>}
            {winnersStatus === "success" && winner && props.awardCategoryID && (
                <>
                    <section className="hero" style={{ backgroundImage: `url('${ImageHelpers.getImageUrl(CaseStudyHelpers.getPrimaryImageUrl(winner.caseStudy), undefined, 1200)}')` }}>
                        <div className="container">
                            <div className="author cotm colour-primary alpha absolute active">
                                <div className="clearfix">
                                    <div className="left">
                                        <div className="imageblock">
                                            <div className="views">
                                                <figure className="icon">
                                                    <img alt="" src={trophy} />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="headings left">
                                            <em className="tag">{winner.awardName} Winner</em>
                                            <h1>{winner.caseStudy?.title}</h1>
                                        </div>
                                    </div>
                                    <div className="left authorinfo">
                                        <p>{winner.caseStudy?.fields?.challenge && winner.caseStudy?.fields?.challenge?.length > 150
                                            ? winner.caseStudy?.fields?.challenge.substring(0, 150) + "..." : winner.caseStudy?.fields?.challenge}</p>
                                        <Link className="button" to={"/casestudies/" + winner.caseStudy?.id}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>)}
            {winnersStatus === "success" && !winner && props.awardCategoryID && (
                <>
                    <section className="pageheading">
                        <div className="colour-tertiary clearfix"><div className="container"><h2>{props.fallbackTitle}</h2></div></div>
                    </section>
                </>)}
        </>
    )
}


interface AwardWinnerHeroPanelProps {
    awardCategoryID?: number,
    fallbackTitle: string
}


export { AwardWinnerHeroPanel }