/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import App from '../App'
import { Default } from '../pages/Default'
import { Role, useAuthContext } from '@pull/pull-groupm-csp-api';
import { Landing } from '../pages/Landing'
import { CaseStudyDetail } from '../pages/case-studies/CaseStudyDetail'
import { CaseStudyEditContainer } from '../pages/case-studies/edit/_CaseStudyEditContainer'
import { CaseStudyStep } from '../modules/case-studies/CaseStudyProgress'
import { DraftCaseStudies } from '../pages/user/DraftCaseStudies'
import { PublishedCaseStudies } from '../pages/user/PublishedCaseStudies'
import { NotFound } from '../pages/NotFound'
import { CaseStudySearch } from '../pages/case-studies/CaseStudySearch';
import { BulkDownloads } from '../pages/user/BulkDownloads';
import { BulkDownloadsHistory } from '../pages/user/BulkDownloadsHistory';
import { Login } from '../pages/auth/Login';
import { Logout } from '../pages/auth/Logout';
import { LoginExternalCallback } from '../pages/auth/LoginExternalCallback';
import { Watchlist } from '../pages/user/Watchlist';
import { Urls } from '../Constants';
import { Award } from '../pages/awards/Award';
import { Judging } from '../pages/awards/Judging';
import { News } from '../pages/general/News';
import { NewsArticle } from '../pages/general/NewsArticle';
import { Page } from '../pages/general/Page';
import { Maintenance } from '../pages/Maintenance';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {

  const { currentSession } = useAuthContext()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        {currentSession ? (
          <Route element={<App />}>
            <Route path='' element={<Default />} />
            <Route path='casestudies/:id' element={<CaseStudyDetail />} />
            <Route path={Urls.CaseStudiesCreate} element={<CaseStudyEditContainer key="newCS" step={CaseStudyStep.Text} />} />
            <Route path='casestudies/:id/edit' element={<CaseStudyEditContainer step={CaseStudyStep.Text} />} />
            <Route path='casestudies/:id/edittags' element={<CaseStudyEditContainer step={CaseStudyStep.Tags} />} />
            <Route path='casestudies/:id/editimages' element={<CaseStudyEditContainer step={CaseStudyStep.Images} />} />
            <Route path='casestudies/:id/editvideo' element={<CaseStudyEditContainer step={CaseStudyStep.Video} />} />
            <Route path='casestudies/:id/editawards' element={<CaseStudyEditContainer step={CaseStudyStep.Awards} />} />
            <Route path='casestudies/:id/publish' element={<CaseStudyEditContainer step={CaseStudyStep.Publish} />} />
            <Route path='search' element={<CaseStudySearch />} />
            <Route path='cotm' element={<Navigate to={"/award/cotm"} />} />
            <Route path='pca' element={<Navigate to={"/award/pca"} />} />
            <Route path='announcements' element={<News />} />
            <Route path='announcements/:id' element={<NewsArticle />} />
            <Route path='pages/:slug' element={<Page />} />
            <Route path='award/:shortName' element={<Award />} />

            {currentSession.role == Role.Judge || currentSession.role == Role.Administrator ? (
              <>
                <Route path='judging' element={<Judging></Judging>} />
              </>
            ) : ""}
            <Route path={Urls.UserCaseStudiesDraft} element={<DraftCaseStudies />} />
            <Route path={Urls.UserCaseStudies} element={<PublishedCaseStudies />} />
            <Route path={Urls.UserBulkDownloads} element={<BulkDownloads />} />
            <Route path={Urls.UserBulkDownloadsHistory} element={<BulkDownloadsHistory />} />
            <Route path={Urls.UserWatchlist} element={<Watchlist />} />
            <Route path={Urls.NotFound} element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        ) : (
          <Route element={<App />}>
            <Route path="/" element={<Landing />} />
            <Route path="*" element={<Navigate to={`/auth?returnUrl=${window.location.pathname + window.location.search}`} />} />
          </Route>
        )}
        <Route path="maintenance" element={<Maintenance />} />
        <Route element={<App />}>
          <Route path="auth" element={<Login />} />
          <Route path="auth/complete" element={<LoginExternalCallback />} />
          <Route path={Urls.UserLogout} element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
